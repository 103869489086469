<template>
  <div class="container">
    <aside-profile/>
    <div class="main-box">
      <div class="category-article">
        <div class="header">
          {{categoryObj.emoji | getEmoji}} {{categoryObj.title}}
        </div>
        <p class="desc">
          {{categoryObj.description}}
        </p>
        <div class="article-list" v-loading="loading">
          <div v-for="item in articleList" :key="item.id" class="article-item">
            <svg t="1622281021777" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2580" width="20" height="20"><path d="M682.61 398.43H284.52v56.87h398.09v-56.87zM284.52 796.52h398.09v-56.87H284.52v56.87z m0-170.61h511.83v-56.87H284.52v56.87z m682.44-386.772v-11.317h-11.318L739.48 11.658V0.341h-11.317L727.82 0l-0.34 0.341h-556.7c-62.84 0-113.74 50.899-113.74 113.74v796.18c0 62.84 50.9 113.739 113.74 113.739h682.44c62.84 0 113.74-50.899 113.74-113.74V243.403l2.104-2.104-2.105-2.16zM739.48 92.072l135.748 135.749H739.48V92.072zM910.09 910.26c0 31.392-25.478 56.87-56.87 56.87H170.78c-31.392 0-56.87-25.478-56.87-56.87V114.081c0-31.392 25.478-56.87 56.87-56.87h511.83v227.48h227.48v625.57z" p-id="2581" fill="#707070"></path></svg>
            <router-link :to="`/article/${item.id}`" class="link">{{item.title}}</router-link>
          </div>
        </div>
        <el-pagination
          hide-on-single-page
          @current-change="handleCurrentChange"
          :current-page.sync="pageNo"
          :page-size.sync="pageSize"
          :total.sync="total"
          layout="total, prev, pager, next"
          style="display: flex;justify-content: center">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import AsideProfile from '@/components/AsideProfile'
import { getCategory } from '@/api/category'
import { listArticle } from '@/api/article'

export default {
  name: 'CategoryArticle',
  components: {
    AsideProfile
  },
  data () {
    return {
      categoryObj: {
        emoji: undefined,
        title: undefined,
        description: undefined
      },
      pageNo: 1,
      pageSize: 5,
      total: 0,
      articleList: [],
      loading: false
    }
  },
  created () {
    const categoryId = this.$route.query.categoryId
    getCategory(categoryId).then(res => {
      this.categoryObj = res.data
    })
    this.getArticleList(categoryId)
  },
  methods: {
    getArticleList (categoryId) {
      this.loading = true
      const query = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        categoryId: categoryId
      }
      listArticle(query).then(res => {
        this.total = res.data.total
        this.articleList = res.data.records
      }).finally(() => {
        this.loading = false
      })
    },
    handleCurrentChange (val) {
      const categoryId = this.$route.query.categoryId
      this.getArticleList(categoryId)
    }
  }
}
</script>

<style lang="less" scoped>
  .container {
    display: flex;
    justify-content: space-between;

    .main-box {
      width: 770px;

      .category-article {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 20px;

        .header {
          font-size: 20px;
          font-weight: bold;
        }

        .desc {
          margin: 20px 0;
        }

        .article-list {
          .article-item {
            display: flex;
            align-items: center;
            margin: 16px 0;

            a {
              margin-left: 10px;
              text-decoration: none;
              color: #333333;
            }
          }
        }
      }
    }
  }
</style>
